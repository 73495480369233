import { IAuthInfo } from "@keyhole/types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";


const initial_state: IAuthInfo = {
  username: '',
  token: ''
}

const credentials_slice = createSlice({
  name: "credentials",
  initialState: initial_state,
  reducers: {
    login: (state, action: PayloadAction<IAuthInfo>) => {
      state.token = action.payload.token;
      state.username = action.payload.username;
    },
    logout: (state) => {
      state = initial_state;
    }
  },
});

export const { login, logout } = credentials_slice.actions;
export default credentials_slice.reducer;