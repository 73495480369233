import helmet from "../helmet.svg";

const Loading = () => {
  return ( 
    <div className="loading">
      <header className="App-header">
        <img src={helmet} className='App-logo' alt='logo' />
        <h1>Loading ...</h1>
      </header>
    </div>
   );
}
 
export default Loading;