 import { useRef, useEffect, useState } from "react";
import { LOGIN_API_PATH, API_KEY } from "../../config/advConfig";
import axios from '../../api/axios';
import { AxiosError } from "axios";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import useAuth from "../../hooks/useAuth";
import Loading from "../../components/Loading";

const Login = () => {
  const { setAuth }:any = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const past = location.state?.from?.pathname || "/home";
  const from = (past == "/login")? "/home" : past;

  const userRef = useRef<HTMLInputElement>(null);
  const errRef = useRef<HTMLParagraphElement>(null);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(userRef.current) {
      userRef.current.focus();
    }
  },[]);

  useEffect(() => {
    setErrMsg('');
  }, [username, password]);
  

  const submitHandler = async (event:React.SyntheticEvent) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(LOGIN_API_PATH, 
        JSON.stringify({username, password}), 
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key':API_KEY,
          },
          // withCredentials: true,
          // to uncomment the above, i need to have access-control-allow-origin on the cors for the gateway set to the domain
        }
      );
      setLoading(false);
      const access_token = response?.data?.data?.access_token;
      const user = response?.data?.data?.user;
      setAuth({user, access_token});
      setUsername('');
      setPassword('');
      navigate(from, {replace: true});
      
    } catch(err: any | AxiosError) {
        setLoading(false);
        if(!err?.response) {
          setErrMsg('No server response');
        } else if(err.response?.status === 401) { setErrMsg('Invalid username or password'); }
        else if(err.response?.status === 400) { setErrMsg('Unauthorized'); }
        else { setErrMsg('Login failed'); }
        if(errRef.current) { errRef.current.focus(); }
    }
  }

  return ( 
    <>
      { loading && (<Loading />)}
      { !loading && (
        <section id="Login">
          <h1>Login</h1>
          <form onSubmit={submitHandler}>
            <div className="grid two-column-grid">

              <label htmlFor="username">Username:</label>
              <input type="text" id="username" 
                ref={userRef} 
                autoComplete="off" 
                onChange={(e) => setUsername(e.target.value)} 
                value={username} 
                required 
              />
            
              <label htmlFor="password">Password:</label>
              <input type="password" id="password"
                onChange={(e) => setPassword(e.target.value)} 
                value={password} 
                required 
              />

              <div className="button-box">
                <button className="button">login</button>
              </div>
              <div className="note-box">
                <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
              </div>
              
            </div>
            
            <p className="fine-print">Not a member? <Link to="/auth/register">Register</Link></p>
          </form>
        </section>
      )}
    </>
    
   );
}
 
export default Login;