import { useRef, useState, useEffect } from "react";
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios_api from "../../api/axios";
import { AxiosError } from "axios";
import { API_KEY, REGISTER_API_PATH } from "../../config/advConfig";
import { INewUser } from "@keyhole/types";
import Loading from "../../components/Loading";

// there are constants for username and password lengths but using them in regex is a pain so hardcoding
// this says username must start with a character then is followed by 2 to 23 additional characters
const USER_REGEX = /^[a-zA-Z0-9-_]{3,23}$/;
// const USER_REGEX = /^[a-zA-Z][a-zA-Z0-9-_]{2,23}$/;
// the password must be between 8 and 24 characters long
const PWD_REGEX = /^.{8,24}$/;

// this regex complies with the email address RFC and is wildly complex
const EMAIL_REGEX = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

const Register = () => {
  const userRef = useRef<HTMLInputElement>(null);
  const errRef = useRef<HTMLParagraphElement>(null);

  const [username, setUsername] = useState('');
  const [validUsername, setValidUsername] = useState(false);
  const [usernameFocus, setUsernameFocus] = useState(false);
  const [usernameError, setUsernameError] = useState('');

  const [password, setPassword] = useState('');
  const [validPassword, setValidPassword] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);
  const [passwordError, setPasswordError] = useState('');

  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [emailError, setEmailError] = useState('');

  const [confirmPassword, setConfirmPassword] = useState('');
  const [validConfirmPassword, setValidConfirmPassword] = useState(false);
  const [confirmPasswordFocus, setConfirmPasswordFocus] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  const [receive_updates, setReceiveUpdates] = useState(false);

  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);

  const [loading, setLoading] = useState(false);

  /***** USE EFFECTS **********/
  useEffect(() => {
    if(userRef.current) {
      userRef.current.focus();
    }
  }, []);

  // clear error message
  useEffect(() => {
    setErrMsg('');
  }, [username, password, email, confirmPassword]);
  
  // ****** username field ****************************
  useEffect(() => { setValidUsername(USER_REGEX.test(username)); }, [username]);
  useEffect(() => {
    if(!validUsername) {
      if( username.length < 3) setUsernameError("username is too short");
      else if(username.length > 23) setUsernameError("username is too long");
    }
    else setUsernameError('');
  }, [validUsername]);

  // ****** email field ****************************
  useEffect(() => { setValidEmail(EMAIL_REGEX.test(email)); }, [email]);
  useEffect(() => {
    if(!validEmail) setEmailError("invalid email address");
    else setEmailError('');
  }, [validEmail]);

  // ****** password field ****************************
  useEffect(() => {
    setValidPassword(PWD_REGEX.test(password));
    const match = password === confirmPassword;
    setValidConfirmPassword(!match);
    setValidConfirmPassword(match);
  }, [password, confirmPassword]);
  useEffect(() => {
    if(!validPassword) setPasswordError("invalid password");
    else setPasswordError('');
    if(!validConfirmPassword) setConfirmPasswordError("passwords don't match");
    else setConfirmPasswordError('');
  }, [validPassword, validConfirmPassword]);

  /***** SUBMIT HANDLER  ******/
  const submitHandler = async (e:React.SyntheticEvent) => {
    e.preventDefault();
    const v1 = USER_REGEX.test(username);
    const v2 = PWD_REGEX.test(password);
    const v3 = EMAIL_REGEX.test(email);
    if(!v1 || !v2 || !v3) {
      setErrMsg("Invalid entry");
      return; 
    }
    try {
      setLoading(true);
      const payload:INewUser = { username, password, email, receive_updates };
      const response = await axios_api.post(REGISTER_API_PATH,
        JSON.stringify(payload), {
          headers: { 'Content-Type':'application/json', 'x-api-key':API_KEY,} });
      setSuccess(true);
      setUsername('');
      setPassword('');
      setReceiveUpdates(false);
      setConfirmPassword('');
      setUsername('');
      setLoading(false);
    } catch(err: any | AxiosError) {
      setLoading(false);
      if(!err?.response) { 
        setErrMsg('No server response');
      } else if (err.response?.status === 409) {
        setErrMsg("Username already taken");
      } else {
        setErrMsg("Registration failed");
      }
      if(errRef.current) {
        errRef.current.focus();
      }
    }
  }

  return ( 
    <>
      {loading && (<Loading />)}
      {success && !loading && (
        <section>
          <h1>Success!</h1>
          <p>Please check your email for a confirmation link, and click the link to continue.</p>
        </section>
      )}
      {!success && !loading && (
        <section id="Registration">
          <h1>Register</h1>
          <form onSubmit={submitHandler}>
            <div className="grid two-column-grid">
              <label htmlFor="email">
                Email:
                <span className={validEmail ? "valid" : "hide" }><FontAwesomeIcon icon={faCheck} /></span>
                <span className={validEmail || !email ? "hide" : "invalid" }><FontAwesomeIcon icon={faTimes} /></span>
              </label>
              <input 
                type="email"
                ref={userRef}
                id="email"
                autoComplete="off"
                onChange={(e) => setEmail(e.target.value)}
                required
                aria-invalid={validEmail ? "false" : "true"}
                aria-describedby="emailnote"
                onFocus={() => setEmailFocus(true)}
                onBlur={() => setEmailFocus(false)}
              />
              <label htmlFor="username">
                Username:
                <span className={validUsername ? "valid" : "hide" }><FontAwesomeIcon icon={faCheck} /></span>
                <span className={validUsername || !username ? "hide" : "invalid" }><FontAwesomeIcon icon={faTimes} /></span>
              </label>
              <input 
                type="text"
                id="username"
                autoComplete="off"
                onChange={(e) => setUsername(e.target.value)}
                required
                aria-invalid={validUsername ? "false" : "true"}
                aria-describedby="uidnote"
                onFocus={() => setUsernameFocus(true)}
                onBlur={() => setUsernameFocus(false)}
              />

              <label htmlFor="password">
                Password:
                <span className={validPassword ? "valid" : "hide"}><FontAwesomeIcon icon={faCheck} /></span>
                <span className={validPassword || !password ? "hide" : "invalid"}><FontAwesomeIcon icon={faTimes} /></span>
              </label>
              <input 
                type="password"
                id="password"
                onChange={(e) => setPassword(e.target.value)}
                required
                aria-invalid={validPassword ? "false" : "true" }
                aria-describedby="pwdnote"
                onFocus={() => setPwdFocus(true)}
                onBlur={() => setPwdFocus(false)}
              />

              <label htmlFor="confirm_pwd">
                Confirm Password:
                <span className={validConfirmPassword && confirmPassword ? "valid" : "hide"}>
                  <FontAwesomeIcon icon={faCheck} />
                </span>
                <span className={validConfirmPassword || !confirmPassword ? "hide" : "invalid"}>
                  <FontAwesomeIcon icon={faTimes} />
                </span>
              </label>
              <input
                type="password"
                id="confirm_pwd"
                onChange={(e) => { setConfirmPassword(e.target.value)}}
                required
                aria-invalid={validConfirmPassword ? "false" : "true"}
                aria-describedby="confirmnote"
                onFocus={() => setConfirmPasswordFocus(true)}
                onBlur={() => { setConfirmPasswordFocus(false)}}
              />

              
              <label htmlFor="receive_updates">
                Receive Updates:
              </label>
              <input
                id="receive_updates"
                className="larger-checkbox"
                type="checkbox" 
                checked={receive_updates} 
                onChange={(e) => setReceiveUpdates(e.target.checked)} 
              />

              <div className="button-box"><button className="button" disabled={!validUsername || !validPassword || !validConfirmPassword ? true : false}>Register</button></div>
              <div className="note-box">
                <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                <p id="uidnote" aria-live="polite" className={usernameFocus && username && !validUsername ? "instructions" : "offscreen"}>
                  {usernameError}
                </p>
                <p id="emailnote" aria-live="polite" className={emailFocus && email && !validEmail ? "instructions" : "offscreen "}>
                  {emailError}
                </p>
                <p id="pwdnote" aria-live="polite" className={pwdFocus && password && !validPassword ? "instructions" : "offscreen"}>
                  {passwordError}
                </p>
                <p id="confirmnote" className={confirmPasswordFocus && confirmPassword && !validConfirmPassword ? "instructions" : "offscreen"}>
                  {confirmPasswordError}
                </p>
              </div>
            </div>
          </form>  
        </section>
      )}
    </>
   );
}
 
export default Register;