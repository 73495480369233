import { Link } from "react-router-dom";
import helmet from "../../helmet.svg";

const Landing = () => {
	return (
		<div className='Landing'>
			<header className='App-header'>
				<img src={helmet} className='App-logo' alt='logo' />
				<h1>The Adventurer</h1>
				<div className='button'><Link to='/auth/login'>Login</Link></div>
				<div className='button'><Link to='/auth/register'>Register</Link></div>
			</header>
		</div>
	);
}

export default Landing;
