import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "../../api/axios";
import { API_KEY } from "../../config/advConfig";
import useRefreshToken from "../../hooks/useRefreshToken";

const AccountPage = () => {
  const [email, setEmail] = useState();
  const [username, setUsername] = useState();
  const refresh = useRefreshToken(); 

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getAccount = async () => {
      try {
        const response = await axios.get('/account', {
          signal: controller.signal,
          headers: { 'x-api-key':API_KEY}
        });
        console.log(response.data);
        isMounted && setEmail(response.data.data.email);
        isMounted && setUsername(response.data.data.username);
      } catch (err) {
        console.error(err);
      }
    }

    getAccount();

    return () => {
      isMounted = false;
      controller.abort();
    }
  },[])

  return ( 
    <div>
      <h1>Account Page</h1>
      <Link to='/home'>Home</Link><br />
      <Link to='/dm'>DM Page</Link>
      <div>username: {username}</div>
      <div>email: {email}</div>
      <button onClick={() => { refresh(); }}>Refresh</button>
    </div>
   );
}
 
export default AccountPage;