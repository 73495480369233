import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div>
      <h1>Home</h1>
      <Link to='/account'>Account Page</Link><br />
      <Link to='/dm'>DM Page</Link>
    </div>
    
   );
}
 
export default Home;