import { Link } from "react-router-dom";

const DMPage = () => {
  return ( 
    <div>
      <h1>DM Page</h1>
      <Link to='/home'>Home</Link><br />
      <Link to='/account'>Account</Link>
    </div>
   );
}
 
export default DMPage;