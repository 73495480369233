import { useState, useEffect } from "react";
import { useParams, Navigate } from "react-router-dom";
import Loading from "../../components/Loading";
import axios_api from "../../api/axios";
import { API_KEY, VALIDATION_API_PATH } from "../../config/advConfig";
import { IAuthInfo } from "@keyhole/types";

const Validation = () => {

  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');
  const {username, token} = useParams();

  const auth:IAuthInfo = { 
    username: 'something',
    token: 'something'
  };

  useEffect(() => {
    if(!username || !token) {
      setSuccess(false);
      setMessage("Invalid credentials");
      setLoading(false);
    } else {
      const payload:IAuthInfo = { username:username, token:token };
      const postValidation = async () => {
        const result = await await axios_api.post(VALIDATION_API_PATH,
          JSON.stringify(payload),
          { headers: {'Content-Type':'application/json', 'x-api-key':API_KEY} });
        setLoading(false);
        setSuccess(true);
      }
      postValidation()
      .catch(() => {
        setError(true);
        setLoading(false);
        setMessage("Validation failed");
      });
    }
    

  }, []);

  return ( 
    <>
      { loading && ( <Loading /> )}
      { success && <Navigate to="/" /> }
      { !loading && error && (
        <>
          <h1>Validation</h1>
          <p>{message}</p>
        </>
      )}
    </>
   );
}
 
export default Validation;