import Layout from './components/Layout';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import { Routes, Route } from 'react-router-dom';
import RequireAuth from './components/RequireAuth';
import Unauthorized from './components/Unauthorized';
import Landing from './pages/Landing/Landing';
import Register from './pages/Register/Register';
import Validation from './pages/Validation/Validation';
import DMPage from './pages/Home/DMPage';
import AccountPage from './pages/Account/Account';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout/>}>
        <Route path="/" element={<Landing/>}/>
        {/* public routes */}
        <Route path="auth/login" element={<Login />} />
        <Route path="auth/register" element={<Register/>} />
        <Route path="auth/validate/:username/:token" element={<Validation/>} />
        <Route path="unauthorized" element={<Unauthorized />} />
        
        {/* protected routes */}
        <Route element={<RequireAuth allowedRoles={[1000, 2000]}/>}>
          <Route path="/home" element={<Home />} />
          <Route path="/account" element={<AccountPage />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={[2000]}/>}>
          <Route path="/dm" element={<DMPage />} />
        </Route>

        {/* <Route element={<RequireAuth allowedRoles={[0,1]}/>}> */}
          
        {/* </Route> */}

        {/* catch all */}
        

      </Route>
    </Routes>
  )
}

export default App;
