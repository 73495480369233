import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";

interface Props {
  allowedRoles: number[];
}

const RequireAuth: React.FC<Props> = ({ allowedRoles }) => {
  const { auth }:any = useAuth(); 
  const location = useLocation();
  
  return ( 
    auth?.user?.adv_role.find((role: number) => allowedRoles?.includes(role))
      ? <Outlet />
      : auth?.user
        ? <Navigate to="/unauthorized" state={{ from:location}} replace />
        : <Navigate to="/auth/login" state={{from:location}} replace />  
   );
}
 
export default RequireAuth;