import React, { createContext, useState, ReactNode } from "react";

interface Props {
  children?: ReactNode
  // any props that come into the component
}

const AuthContext = createContext({});

export const AuthProvider = ({ children }:Props) => {
  const [auth, setAuth] = useState({});

  return (
    <AuthContext.Provider value={{auth, setAuth}}>
      {children}
    </AuthContext.Provider>
  )
};

export default AuthContext;